<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Agrupar 
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchPedidosIngresados"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <h3 class="title">Seleccione los pedidos que va a comenzar a preparar:</h3>
                        <div class="datepicker-trigger">
                            <v-text-field
                                
                                id="datepicker-trigger"
                                placeholder="Filtrar por rango de fechas"
                                :value="formatDates(dateOne, dateTwo)"
                            />
                            <AirbnbStyleDatepicker
                                :trigger-element-id="'datepicker-trigger'"
                                :mode="'range'"
                                :fullscreen-mobile="true"
                                :date-one="dateOne"
                                :date-two="dateTwo"
                                @date-one-selected="val => { dateOne = val }"
                                @date-two-selected="val => { dateTwo = val }"
                            />
                        </div>
                        
                        <v-data-table
                            :headers="headersPedidosIngresados"
                            :items="tablePedidosIngresados"
                            show-select
                            v-model="selectedPedidosIngresados"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            :sort-by="'id'"
                            :sort-desc="true"
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.ingreso="{ item }">
                                <td class="text-center">
                                    {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                </td>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="text-center">
                                    <b-button size="sm"  pill variant="info">{{item.order_state.name}}</b-button>
                                </td>
                            </template>
                            <template v-slot:item.fuente="{ item }">
                                <td class="text-center">
                                    {{ item.source.name }}
                                </td>
                            </template>
                        </v-data-table>
                        <v-btn @click="preparing" v-show="selectedPedidosIngresados.length" small color="cyan" class="float-left" >Comenzar a preparar</v-btn>
                        <br><br>
                        <h3 class="title mt-4">Filtrar búsqueda:</h3>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-select
                                v-model="selectTienda"
                                :items="tiendasList"
                                label="Por Tienda"
                                required
                                @change="updateEstados"
                            ></v-select>
                            <v-select
                                v-model="selectEstado"
                                :items="estadosList"
                                label="Por Estado"
                                required
                                :disabled="!selectTienda || selectTienda < -1"
                                
                            ></v-select>
                        </v-form>
                        <div class="d-flex justify-space-between">
                        <v-btn @click="searchPackages" :disabled="!selectEstado || selectEstado < -1"  color="primary" dark class="float-left"
                        ><v-icon left dark>mdi-file-find-outline</v-icon> Buscar</v-btn>
                        <v-text-field style="margin-left: 30px; margin-right: 30px; width: 100px;" label="Buscar pedido" v-show="!!tableData.length" v-model="nroPedidoField" @keydown.enter="autoSeleccionarPedido"></v-text-field>
                        <v-btn :disabled="!tableData.length || $store.state.passport.loadingStack > 0"  color="success" @click="downloadPdf" dark class="float-right"
                        >Descargar PDF <v-icon right dark>mdi-file</v-icon></v-btn
                        >
                        </div>
                        <!--
                        <br><br>
                        <h3 class="title">Resultados:</h3>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            :search="search"
                            :single-select="true"
                            show-select
                            v-model="selected"
                            :sort-by="'id'"
                            :sort-desc="true"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                        >
                            <template v-slot:item.current_state="{ item }">
                                <td class="text-center">
                                    <b-button size="sm"  pill variant="success">{{item.current_state.name}}</b-button>
                                    
                                </td>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'envio', params: {envio: item.id}})"
                                            >
                                                mdi-arrow-right-box
                                            </v-icon>
                                        </template>
                                        <span>Ver detalles</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})"
                                            >
                                                mdi-cart-arrow-right
                                            </v-icon>
                                        </template>
                                        <span>Ver orden</span>
                                    </v-tooltip>
                                </td>
                            </template>
                        </v-data-table>
                        <br><br>
                        <v-btn @click="searchPickings" v-show="selected.length" small color="primary" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon> Mostrar items</v-btn>
                        <br><br>
                        <h3 class="title mt-2" v-show="tableDetails.length">Detalles:</h3>
                        <v-data-table
                            :headers="headersDetails"
                            :items="tableDetails"
                            :search="searchDetails"
                            :single-select="true"
                            show-select
                            v-show="tableDetails.length"
                            v-model="selectedDetails"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                        >
                            <template v-slot:item.added="{ item }">
                                <td class="text-center">
                                    <v-switch
                                        :disabled="true"
                                        v-model="item.added"
                                        :label="item.added ? 'Asignado': 'No asignado'"
                                    ></v-switch>
                                </td>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <td>
                                    
                                </td>
                            </template>
                        </v-data-table>
                        <br><br>
                        <div class="row" v-if="tableDetails.length">
                            <div class="col-md-4">
                                <v-text-field
                                    v-model="inputSku"
                                    label="SKU:"
                                    :disabled="loadingAssign"
                                    @keyup.enter="assignBySku"
                                ></v-text-field>
                            </div>
                            <div class="col-md-4 d-flex align-center">
                                <v-btn @click="assignBySku" :disabled="loadingAssign" block color="cyan" dark >Ingresar</v-btn>
                            </div>
                        </div>
                        <br><br>
                        <v-btn @click="finishPicking" v-show="AllAsigned" small color="success" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon> Finalizar</v-btn>
                        -->
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data () {
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            loadingAssign: false,
            range: {},
            search: '',
            searchDetails: '',
            selected: [],
            selectedDetails: [],
            nroPedidoField: undefined,
            headers: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Estado', value: 'current_state' },
                { text: 'Fecha Ingreso', value: 'created_at' },
                { text: 'Actions', value: 'acciones', sortable: false },
            ],
            tableData: [],
            tableDetails: [],
            headersDetails: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Nombre', value: 'name' },
                { text: 'SKU', value: 'sku' },
                { text: 'EAN', value: 'ean' },
                { text: 'Cantidad', value: 'quantity' },
                { text: 'Asignado', value: 'added' },
                { text: 'Actions', value: 'acciones', sortable: false },
            ],
            headersPedidosIngresados: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Fecha Ingreso', value: 'ingreso', filter: this.filtroRangoFechas },
                { text: 'Estado', value: 'estado' },
                { text: 'Fuente', value: 'fuente' },
            ],
            tablePedidosIngresados: [

            ],
            selectedPedidosIngresados: [],
            searchPedidosIngresados: '',
            valid: false,
            tiendasList: [
                {text: 'Cualquier Tienda', value: -1}
            ],
            selectTienda: undefined,
            selectEstado: undefined,
            estadosList: [
                {text: 'Cualquier Estado', value: -1}
            ],
            inputSku: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Picking", route: "/picking" },
        ]);
        this.fetchPackagesStatesList();
        this.fetchPedidosIngresados();
    },
    methods: {
        downloadPdf()
        {
            this.axios({
                url: 'picking/get_packages_pdf',
                method: 'POST',
                data: {
                    store: this.selectTienda,
                    state: this.selectEstado
                }
            }).then( response => {
                window.open(response.data, "_blank");
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        fetchPedidosIngresados(){
            var vm = this;
            this.axios({
                url: 'orders/by_state/DEFAULT_IN_OMS',
                method: 'POST',
                data: {
                    store: this.$store.state.passport.user.default_store_id,
                    context: 'PRE_PICKING'
                }
            }).then( response => {
                vm.tablePedidosIngresados = response.data.data;
            }).catch( error => {
                console.log(error);
            });
        },
        fetchPackagesStatesList(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'orders/stores/data_select_input'
            }).then( response => {
                vm.tiendasList = response.data;
            }).catch( error => {
                console.log(error);
            });
        },
        preparing()
        {
            
            console.log('ir a marcar como preparando los pedidos seleccionados');
            this.axios({
                url: 'orders/bulk_prepare',
                method: 'POST',
                data: {
                    orders: this.selectedPedidosIngresados.map( e => e.id)
                }
            }).then( response => {
                this.fetchPedidosIngresados();
                
            }).catch( error => {
                console.log(error);
            });
        },
        updateEstados()
        {
            if( !this.selectTienda || this.selectTienda < 1 ){
                //alertar que se selecciono algo inválido
                return;
            }
            var vm = this;
            this.axios({
                url: 'picking/states/data_select_input/by_store/'+this.selectTienda,
                method: 'GET'
            }).then( response => {
                if(response.data.length){
                    vm.estadosList = [{text: 'Todos los estados', value: -1}];
                    response.data.forEach(element => {
                        vm.estadosList.push(element);
                    });
                    vm.selectEstado = -1;
                }
            }).catch( error => {
                console.log(error);
            });
        },
        searchPackages()
        {
            var vm = this;
            this.axios({
                url: 'picking/packages/json', 
                method: 'POST',
                data: {
                    store: this.selectTienda,
                    state: this.selectEstado
                },
                params: {
                    "pagination[page]": "1",
                    "pagination[pages]": "24",
                    "pagination[perpage]": "10",
                    "pagination[total]": "234",
                    "sort[sort]": "asc",
                    "sort[field]": "id",
                    "query": ""
                }
            }).then( response => {
                vm.tableData = response.data.data;
            }).catch( error => {
                console.log(error);
            })
        },
        searchPickings()
        {
            var vm = this;
            this.axios({
                url: 'picking/package_details',
                method: 'POST',
                data: {
                    package: this.selected[0].id
                }
            }).then( response => {
                vm.tableDetails = response.data.data.map(e => ({
                    ...e,
                    counter: 0
                }))
            }).catch( error => {
                console.log(error);
            });
        },
        assignBySku()
        {
            let sku_query = this.inputSku;
            if(this.inputSku)
            {
                this.loadingAssign = true;
                var vm = this;
                this.axios({
                    url: 'inventory/preg_generic_sku',
                    method: 'POST',
                    data: {
                        custom_sku: sku_query
                    }
                }).then( response => {
                    const generic_sku = response.data;
                    let indice = vm.tableDetails.findIndex(e => e.sku == generic_sku );
                    if(indice == -1) {
                        return;
                    }
                    else {
                        if(indice > -1){
                            this.tableDetails[indice].counter++;
                            if(this.tableDetails[indice].counter >= vm.tableDetails[indice].quantity){
                                this.tableDetails[indice].added = true;
                            }
                        }
                    }
                    vm.loadingAssign = false;
                    vm.inputSku = '';
                }).catch( error => {
                    vm.loadingAssign = false;
                });
            }
            
                
        },
        finishPicking()
        {
            var vm = this;
            this.axios({
                url: 'picking/finish',
                method: 'POST',
                data: {
                    package: this.selected[0].id
                }
            }).then( response => {
                vm.searchPackages();
            }).catch( error => {
                console.log(error);
            });
        },
        autoSeleccionarPedido()
        {
            let query = this.tableData.find(e => e.id == this.nroPedidoField);
            if(query){
                this.selected = [];
                this.selected.push(query) ;

                this.searchPickings();
            }

        }
    },
    computed: {
        AllAsigned()
        {
            let total = this.tableDetails.length;
            let contador = 0;
            this.tableDetails.forEach( elem => {
                if(elem.added){
                    contador++;
                }
            });
            if( total != 0 && contador == total) return true;
            else return false;
        }
    }
}
</script>